import { type ApplicationIdentifier, type ErrorCodes } from '@tgg/common-types';
import {
    TggTraceCorrelationParameters,
    type PaymentTokenPersistanceLayer,
} from '@tgg/util';

export interface EncodedPaymentResult {
    value: string;
    type: 'ThreeDSOne' | 'ThreeDSTwo';
}

export interface ChallengeOutcomeProperties {
    encodedPaymentResult: EncodedPaymentResult;
    applicationIdentifier: ApplicationIdentifier;
    paymentTokenPersistanceLayer: PaymentTokenPersistanceLayer;
    paymentId: string;
    loggerParameters: TggTraceCorrelationParameters;
}

export interface ChallengeOutcomePayload3Ds1 {
    PaRes: string;
    MD: string;
    MDX: string;
}
export interface ChallengeOutcomePayload3Ds2 {
    cres: string;
    threeDSSessionData: string;
}

/* istanbul ignore next */
export function is3Dv1(
    payload: ChallengeOutcomePayload3Ds1 | ChallengeOutcomePayload3Ds2,
): payload is ChallengeOutcomePayload3Ds1 {
    return (payload as ChallengeOutcomePayload3Ds1).PaRes !== undefined;
}

export type ThreeDSPayentEvent = {
    code: ErrorCodes | 'OK';
    type: 'TggPayment';
    error?: unknown;
    redirect?: string;
};

export function isThreeDSPayentEvent(
    object: unknown,
): object is ThreeDSPayentEvent {
    return (object as ThreeDSPayentEvent).type === 'TggPayment';
}
